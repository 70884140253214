* {
  margin : 0;
  padding : 0;
}

.App,#root,html,body {
  height: 100%;
  overflow-x: hidden;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.brown-icon-separator {
  background: url('assets/agrobeads_earth.png') no-repeat;
  height: 128px;
  background-size: contain;
}

h2 { text-transform: capitalize;}

p, h2 {
  color: #043B2E;
}
